import React from "react";
// @material-ui/core components
// core components

import './style.css';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table";
import InputLabel from "@material-ui/core/InputLabel";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Grid, Row, Col } from 'react-flexbox-grid';
import Button from "components/CustomButtons/Button.js";
import { Link,NavLink,Switch,Route } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import Modal from 'react-modal';
import { withStyles } from "@material-ui/core/styles";
import axios from 'axios';
import {Redirect} from 'react-router-dom'

class Rubriques extends React.Component  {
  
handleChange (evt, field) {
  this.setState({ [field]: evt.target.value });

}
handleSubmit1 = event => {
  event.preventDefault();
  const subsub=[];
  this.state.subRubriques.forEach(rub=>{
   
    if(document.getElementById(rub.id).checked)
    subsub.push({id:rub.id,titre:rub.body.titre})
  })
  const rubrique = {
    id:this.state.idRub,
    subRubrique:subsub
  };

  axios.post(`https://ckm.onrender.com/api/rubriques/add/sub_rubrique`, rubrique)
    .then(res => {
      console.log(res);
      console.log(res.data);
      alert("Rubrique ajouté avec succées!");
      // window.location = "/admin/rubList";
     
    }).catch(error=>{
      console.log(error.message);
      alert("Il faut remplir tous les champs!!!");
    })
}
handleSubmit = event => {
  event.preventDefault();

  const rubrique = {
    titre:this.state.Title,
    isSub:this.state.isSub=="on",
  };

  axios.post(`https://ckm.onrender.com/api/rubriques/add`, {rubrique})
    .then(res => {
      console.log(res);
      console.log(res.data);
      alert("Rubrique ajouté avec succées!");
      window.location = "/admin/rubList";
     
    }).catch(error=>{
      console.log(error.message);
      alert("Il faut remplir tous les champs!!!");
    })
}

  componentDidMount() {
    axios.get(`https://ckm.onrender.com/api/rubriques/`)
      .then(res => {
    
        const Rubriques = res.data.filter((rubrique) => rubrique.body.isSub !== true);
        this.setState({ Rubriques });

      })
      axios.get(`https://ckm.onrender.com/api/rubriques/`)
      .then(res => {
    
        const subRubriques = res.data.filter((rubrique) => rubrique.body.isSub !== false);
        this.setState({ subRubriques });

      })
  }
  constructor() {
    super();
    this.state = {
    Rubriques: [],
    subRubriques: [],
    subrubrique:[],
    isSub:false,
    modalIsOpen:false,
    modal1IsOpen:false,
    idRub:"",
    Title:"",
    }; 
      
  }

  openModal() {
    this.setState({
      modalIsOpen:true,
    
    });
       
  }

  closeModal() {
    this.setState({
      modalIsOpen:false,
    });
       
  }
 
  openModal1(id) {
    this.setState({
      modal1IsOpen:true,
      idRub:id,
    });
       
  }

  closeModal1() {
    this.setState({
      modal1IsOpen:false,
    });
       
  }
 
  render(){
    const customStyles = {
      content : {
     
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
      }
    };
    const {navigate}=this.state;
    if(navigate){
      alert("Connectez vous s'il vous plais")
        return <Redirect to="/" push={true} />
    }
    
    if(this.state.LoggedIn===false){
      alert("Connectez vous s'il vous plais")
        return <Redirect to="/"/>
    }
  return (   
     <view>
    
     <Card > 
  <CardBody>
  <Grid fluid>
    <Row>
    <Col  xs >
<h4 >Liste des rubriques <Button   onClick={()=>this.openModal()} color="white"><AddIcon name="add"></AddIcon>Ajouter</Button></h4>

   </Col>
  
    </Row>
    <Row>
   
    </Row>
    <Row>
    <Table
              tableHeaderColor="gray"
              tableHead={["Titre","Actions"]}
              tableData={this.state.Rubriques.map(rubrique =>[
        
                rubrique.body.titre,<Button  onClick={()=>this.openModal1(rubrique.id)}  color="danger">Attribuer </Button>
                
                ,
               
               
              ]
              )}
              
            />
    </Row>
    </Grid>
 
  </CardBody>
  
</Card>


<Modal isOpen={this.state.modal1IsOpen}
            onRequestClose={() => {this.closeModal1()} }
            style={customStyles}
            contentLabel="Example Modal"
        >
<form onSubmit={this.handleSubmit1}>   
<h2 >Attribuer une sous-rubrique</h2>

{ this.state.subRubriques.map(subrubrique =>[
   <Grid><Row> <Col><InputLabel style={{ color: "#AAAAAA" }}>{subrubrique.body.titre}</InputLabel></Col>
                
        <Col><input type="checkbox" style={{"margin-left":"10px"}} value={subrubrique.body.titre} id={subrubrique.id}  /></Col></Row>
    
     </Grid>  
      ]
              )}
            
<Button  type="submit">Attribuer</Button>
            
            <Button  onClick={()=>this.closeModal1()}>Cancel</Button>
</form>

          </Modal>




<Modal isOpen={this.state.modalIsOpen}
            onRequestClose={() => {this.closeModal()} }
            style={customStyles}
            contentLabel="Example Modal"
        >
 
 <form onSubmit={this.handleSubmit}>          
         
          <h2 >Ajouter une rubrique</h2>
     
          <br></br>
                <InputLabel style={{ color: "#AAAAAA" }}>Titre</InputLabel>
                
                     <input style={{width:"240px", border: 'none','border-bottom': '2px solid #AAAAAA ' }} type="text"   onChange={(event)=>this.handleChange(event, "Title")} />
<br></br><br></br>
<Grid><Row> <Col>   <InputLabel style={{ color: "#AAAAAA" }}>Sous-rubrique</InputLabel>
                </Col>
            
                <Col>    <input style={{"margin-left":"10px"}} type="checkbox"   onChange={(event)=>this.handleChange(event, "isSub")} /></Col>
                </Row></Grid>

            

            <br></br><br></br>
            <Button  type="submit">Ajouter</Button>
            
            <Button  onClick={()=>this.closeModal()}>Annuler</Button>
          </form>
        </Modal>
</view>        
        
        );
      }
    }
    
    export default withStyles( { withTheme: true })(Rubriques);