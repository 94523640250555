import React from "react";
// @material-ui/core components
// core components

import "./style.css";
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import { Grid, Row, Col } from "react-flexbox-grid";
import { withStyles } from "@material-ui/core/styles";
import Card from "../../components/Card/Card";
import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody.js";

import { Redirect } from "react-router-dom";
import Presentation from "./presentation";
import Historique from "./historique";
import Atouts from "./Atouts";
import Valeurs from "./Valeurs";
import Politique from "./Politique";

class TableList extends React.Component {
  componentDidMount() {
    setInterval(() => this.currentTime(), 1000);
  }
  constructor() {
    const token = localStorage.getItem("token");

    let LoggedIn = true;

    if (token == null) {
      LoggedIn = false;
    }
    super();
    this.state = {
      LoggedIn,
      navigate: false,
      token: "",
    };
  }

  currentTime() {
    this.setState({
      time: new Date(),
    });
  }

  render() {
    const { navigate } = this.state;
    if (navigate) {
      alert("Connectez vous s'il vous plais");
      return <Redirect to="/" push={true} />;
    }

    if (this.state.LoggedIn === false) {
      alert("Connectez vous s'il vous plais");
      return <Redirect to="/" />;
    }

    return (
      <view className="Card">
        <Presentation />
        <Valeurs />
        <Historique />
        <Politique />
        <Atouts />
      </view>
    );
  }
}

export default withStyles(styles, { withTheme: true })(TableList);
