import React from "react";
// @material-ui/core components
// core components

import './style.css';
import classNames from "classnames";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popper from "@material-ui/core/Popper";
import Card from "../../components/Card/Card";
import Card2 from "../../components/UI/Card";
import CardBody from "components/Card/CardBody.js";
import { Grid, Row, Col } from 'react-flexbox-grid';
import Button from "components/CustomButtons/Button.js";
//import CustomInput from "components/CustomInput/CustomInput.js";
import Search from "@material-ui/icons/Search";
import FilterListIcon from '@material-ui/icons/FilterList';
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";

import Modal from 'react-modal';
import "react-datepicker/dist/react-datepicker.css";
import HideButton from "components/HideButton/index.js"
import axios from 'axios';
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";

import {Redirect} from 'react-router-dom'


class TableList extends React.Component  {
  componentDidMount() {
    setInterval(()=>this.currentTime(),1000)
    axios.get(`https://ckm.onrender.com/api/rubriques/`)
    .then(res => {
      const Rubrique = res.data;
      
      const Rubriques = Rubrique.filter((rubrique) => rubrique.body.titre == "Présentation de CKM");
       Rubriques.map(rubrique =>this.setState({ RubriquesId :rubrique.id}))
    
       axios.get(`https://ckm.onrender.com/api/announces/`)
       .then(res => {
         const id = this.state.RubriquesId;
         const announces1 = res.data;
         const announces = announces1.filter((announce) => announce.body.rubriqueId == id  );
         announces.map(announce =>this.setState({ Description :announce.body.description}))
         this.setState({ announces });
       })
    })

  

  }

  handleChange (evt, field) {
    this.setState({ [field]: evt.target.value });
  
  }
 
  handleSubmit = event => {
    const id = this.state.RubriquesId;
    event.preventDefault();
    const announce = {
      titre:"présentation",
      description: this.state.Description,
      image:"",
      visib:false,
      rubriqueId:id,
      files:"",
    };
    try{ 
      axios.post(`https://ckm.onrender.com/api/announces/add`, {announce} )
   .then(res => {
     console.log(res);
     console.log(res.data);
     alert("Announce added successfully!");
     window.location = "/admin/Ckmetrologie";
    
   }).catch(error=>{
     console.log(error.message);
   
   })}
   catch(error){
     alert("Announce already added!");
   }
 }
 handleSubmit1 = event => {
  const id = this.state.RubriquesId;
  event.preventDefault();
  const announce = {
    token:localStorage.getItem('token'),
    id:this.state.id,
    titre:"présentation",
    description:this.state.Description,
    image:"h",
    rubriqueId:id,
    files:""
    
  };
  try{ 
    axios.put(`https://ckm.onrender.com/api/announces/put`, announce)
      .then(res => {
        console.log(res);
        console.log(res.data);
        alert("Announce modified successfully!")
        window.location = "/admin/Ckmetrologie";
       
 }).catch(error=>{
   console.log(error.message);
 
 })}
 catch(error){
   alert("Announce already added!");
 }
}
  constructor() {
    const token=localStorage.getItem("token")
    
    let LoggedIn=true
  
    if(token==null){
        LoggedIn=false
    }
    super();
    this.state = {
      Description:"",
      RubriquesId:"",
      LoggedIn,
      navigate:false,
      announces: [],
      type:null,
      modalIsOpen:false,
      id:"",
      modalIsOpen1:false,
      token:""
  
    }; 
      
  }
  openModal1(id1){
    this.setState({
      modalIsOpen1:true,
      id:id1
     
    });
       
  }
  closeModal1(){
    this.setState({
      modalIsOpen1:false,
      
    });
    } 
   openModal() {
    this.setState({
      modalIsOpen:true,
    });
       
  }
currentTime(){
    this.setState({
      time:new Date()
    })
  }
  
  closeModal(){
    this.setState({
      modalIsOpen:false,
   
    });
    }
  
     
render(){
  const customStyles = ({
    content : {
      height:'60vh ' ,
      width:'60vh ' ,
      top                   : '50%',
      left                  : '60%',
      right                 : '0%',
      bottom                : 'auto',
      transform             : 'translate(-50%, -50%)'
    }
  });
  const {navigate}=this.state;
  if(navigate){
    alert("Connectez vous s'il vous plais")
      return <Redirect to="/" push={true} />
  }
  
  if(this.state.LoggedIn===false){
    alert("Connectez vous s'il vous plais")
      return <Redirect to="/"/>
  }
       
  return (   
    
     <view className="Card">
        {this.state.announces.length == 0  ? 
        <Card > 
         <CardBody>
         <Grid fluid>
           <Row>
         <Col xs>
           <h2>Présentation de CKM</h2>
           </Col>
           <Col xs>
   <Button  style={{width:"100px"}} onClick={() => {this.openModal()} }  color="info" >Ajouter </Button>
   </Col>
   </Row>
   </Grid>
   </CardBody>
   </Card>
   :
       this.state.announces.map(announce =>
    
<Card > 
            <CardBody>
            <Grid fluid>
              <Row>
            <Col xs>
              <h2>Présentation de CKM</h2>
                            </Col>
              <Col xs>
              <h4 >{announce.body.description}</h4>
          
             </Col>
              <Col xs={6} md={2}>
          <Row xs><Button  style={{width:"100px"}} onClick={() => {this.openModal1(announce.id)} }  color="info" >Editer</Button></Row>              </Col>
              </Row>
              </Grid>
            </CardBody>
        </Card>
        
  ) }
         <Modal className="Card" isOpen={this.state.modalIsOpen}
            onRequestClose={() => {this.closeModal()} }
            style={customStyles}
            contentLabel="Example Modal"
        >
 
 <form onSubmit={this.handleSubmit}>          
         
          <h2 >Ajouter </h2>
     
          <br></br>
                <InputLabel style={{ color: "#AAAAAA" }}>Présentation</InputLabel>

                     <textarea rows="8"  style={{width:"400px", border: 'none','border-bottom': '2px solid #AAAAAA ' }} type="text"   onChange={(event)=>this.handleChange(event, "Description")} />
             


<br></br><br></br>            <Button  type="submit">Ajouter</Button>
            
            <Button  onClick={()=>this.closeModal()}>Annuler</Button>
          </form>
        </Modal>
        <Modal 
     
        isOpen={this.state.modalIsOpen1}
            onRequestClose={() => {this.closeModal1()} }
            style={customStyles}
            contentLabel="Example Modal"
            
        >
 
 <form onSubmit={this.handleSubmit1}>          
         
          <h2 >Editer </h2>
     
          <br></br>
                <InputLabel style={{ color: "#AAAAAA" }}>Présentation</InputLabel>

                     <textarea  rows="8"  style={{width:"400px", border: 'none','border-bottom': '2px solid #AAAAAA ' }} type="text" value={this.state.Description} onChange={(event)=>this.handleChange(event, "Description")} />
             


<br></br><br></br>            <Button  type="submit">Editer</Button>
            
            <Button  onClick={()=>this.closeModal1()}>Annuler</Button>
          </form>
        </Modal>
        </view>        
        
        );
      }
    }
    
    export default withStyles(styles, { withTheme: true })(TableList);